import { ReactElement, useEffect, useState } from 'react';
import { Row, Col, Grid, Descriptions, Collapse } from 'antd';
import type { CollapseProps } from 'antd';
import { motion } from "framer-motion";
import { MotionDivType, pauseWelcomePageEffectsDurationInMinutes } from "../../../../constants";
import { pauseWelcomeEffects } from "../../../../functions/pause-welcome-effects";
import { ScrollTriggeredDiv } from '../../../ui/molecules/scroll-triggered-div';
import { NetherlandsFlagText } from '../../../ui/molecules/netherlands-flag-text';
import { HoverKeyWord } from '../../../ui/molecules/hover-key-word';
import { CenterScreenModal } from '../../../ui/molecules/modal-center-screen';
import { 
  CVData, 
  TechnicalProficiency,
  CareerExperience,
  Education
} from '../../root/interfaces';
import '../../../../styles/welcome.css';

const { useBreakpoint } = Grid;

interface WelcomeProps {
  cvData: CVData;
}

const collapseItems: CollapseProps['items'] = [
  {
    key: '1',
    label: <><span>Work status </span><span style={{fontSize: '10px'}}>&#40;Last updated: Novembe 18th, 2024&#41;</span></>,
    children: <Descriptions
      size="small"
      style={{marginBottom: '0px'}}
    >
      <Descriptions.Item label="Open to work"><span className='dot'></span>&nbsp;Yes</Descriptions.Item>
      <Descriptions.Item label="Current location">USA</Descriptions.Item>
      <Descriptions.Item label="Lead time">3 weeks</Descriptions.Item>
    </Descriptions>
  },]

export const Welcome: React.FC<WelcomeProps> = (props) => {
  const [name, setName] = useState<string | ReactElement>(props.cvData.personalInformation.name);
  const screens = useBreakpoint();
  const [showWelcomeEffects, setShowWelcomeEffects] = useState<boolean>(false);
  const [isCollapseStateSet, setIsCollapseStateSet] = useState(false);
  const [collapseActiveKey, setCollapseActiveKey] = useState<string | string[]>(['0']);

  //reset how long to pause welcome special effects
  useEffect(() => {
    setShowWelcomeEffects(pauseWelcomeEffects());
  }, []);

  useEffect(() => {
    if (!isCollapseStateSet) {
      if (screens.md) {
        setIsCollapseStateSet(true);
        setCollapseActiveKey(['1']);
      } else if (screens.xs || screens.sm) {
        setIsCollapseStateSet(true);
        setCollapseActiveKey(['0']);
      } else {
        setCollapseActiveKey(['0']);
      }
    }
  }, [screens]);

  const mapExpertise = () => {
    const expertise = props.cvData.areasOfExpertise.expertise;
    const rows = [];
    
    if (screens.md) {
      for (let i = 0; i < expertise.length; i += 3) {
        const cols = expertise.slice(i, i + 3).map((area: any, index: any) => (
          <Col span={8} key={index} className="gutterBox">
            • <HoverKeyWord text={area} showWelcomeEffects={showWelcomeEffects} />
          </Col>
        ));    
        rows.push(<Row key={i}>{cols}</Row>);
      }
    } else if (screens.sm) {
      for (let i = 0; i < expertise.length; i += 2) {
        const cols = expertise.slice(i, i + 2).map((area: any, index: any) => (
          <Col span={12} key={index} className="gutterBox">
            • <HoverKeyWord text={area} showWelcomeEffects={showWelcomeEffects} />
          </Col>
        ));    
        rows.push(<Row key={i}>{cols}</Row>);
      }
    } else {
      const cols = expertise.map((area: any, index: any) => (
        <Col span={24} key={index} className="gutterBox">
          • <HoverKeyWord text={area} showWelcomeEffects={showWelcomeEffects} />
        </Col>
      ));
      rows.push(<Row>{cols}</Row>);
    }
    return rows;
  };

  const mapTechnicalProficiencies = () => {
    // for each item in technicalProficiencies map the title and items onto a row
    const technicalProficiencies = props.cvData.technicalProficiencies;
    const rows = [];

    const cols = technicalProficiencies.map((proficiency: TechnicalProficiency, index: any) => (
      <Col span={24}>
        <Row>
          <Col><b>{proficiency.category}:</b>&nbsp;</Col>
          <Col>
            {proficiency.items.map((item: string, index: any) => (
              // print item with a comma after it unless it is the last item
              index < proficiency.items.length - 1
              ? <HoverKeyWord text={item} withComma={true} showWelcomeEffects={showWelcomeEffects}/>
              : <HoverKeyWord text={item} showWelcomeEffects={showWelcomeEffects}/>
            ))}
          </Col>
        </Row>
      </Col>
    ));
    rows.push(<Row>{cols}</Row>);
    
    return rows;
  };

  const mapCareerExperience = () => {
    const careerExperience = props.cvData.careerExperience;
    const cols = careerExperience.map((experience: CareerExperience, index: any) => (
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Row>
              <Col><b>{experience.employer}</b>,&nbsp;</Col>
              <Col>{experience.location.city}, {experience.location.state}</Col>
            </Row>
            <Row>
              <Col>{experience.title}</Col>
            </Row>
          </Col>
          <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Row>
              <Col>{experience.duration.start.month} {experience.duration.start.year} - {decipherEndDate(experience.duration.end)}</Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>{experience.summary}</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ul>
              {experience?.accomplishments?.map((accomplishment: any, index: any) => (
                <li>{accomplishment}</li>
              ))}
            </ul>
          </Col>
        </Row>
      </Col>
    ));

    return cols;
  }

  const mapEducation = () => {
    const education = props.cvData.education;
    const cols = education.map((school: Education, index: any) => (
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Row>
              <Col><b>{school.school}</b>,&nbsp;</Col>
              <Col>{school.location.city}, {school.location.state}, {school.location.country}</Col>
            </Row>
            <Row>
              <Col>{school.degree} in {school.major}</Col>
            </Row>
          </Col>
          <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Row>
              <Col>{school.duration.start.month} {school.duration.start.year} - {decipherEndDate(school.duration.end)}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
    ));

    return cols;
  }

  const decipherEndDate = (date: any) => {
    if (date === undefined || date === null) {
      return 'Present';
    } else {
      if (date.month === undefined || date.year === undefined) {
        if (date.year === undefined) {
          return `Present`;
        }
        else {
          return `${date.year}`;
        }
      }
      return `${date.month} ${date.year}`;
    }
  }

  const getModalText = () => {
    if (screens.md) {
      return <span>Hover over <span style={{color: 'red'}}>colored</span> text to find out more.</span>;
    } else if (screens.sm) {
      return <span>Hover or click on <span style={{color: 'red'}}>colored</span> text to find out more.</span>;
    } else {
      return <span>Click on <span style={{color: 'red'}}>colored</span> text to find out more.</span>;
    }
  };

  const onTitleHoverStart = (e: any) => {
    setName(<NetherlandsFlagText text={props.cvData.personalInformation.name}/>);
  }

  const onTitleHoverEnd = (e: any) => {
    setName(props.cvData.personalInformation.name);
  }

  const onCollapseChange = (key: string | string[]) => {
    setCollapseActiveKey(key);
  };

  return (
    <>
    <Row>
      <Col span={24} style={{
        backgroundImage: `url("https://interactivecveast2.blob.core.windows.net/photos/background-amsterdam.jpg")`, 
        backgroundSize: 'cover',
        backgroundColor: '#ECECEC'
      }}>
        <Row className="cvDefaultText">
          <Col
            xs={{span: 22, offset: 1}}
            md={{span: 20, offset: 2}}
            lg={{span: 18, offset: 3}}
            className="cvBackground"
          >
            <Collapse
              items={collapseItems}
              activeKey={collapseActiveKey}
              size="small"
              style={{marginTop: '2px'}}
              onChange={onCollapseChange}
            />
            <p style={{ fontSize: '30px'}}>
              <motion.div
                animate={{
                  scale: [4, 3, 2, 1],
                  opacity: [0, .25, .5, 1],
                }}
                onHoverStart={e => {onTitleHoverStart(e)}}
                onHoverEnd={e => {onTitleHoverEnd(e)}}
                style={{ textAlign: 'center'}}
                transition={{ duration: 1.25 }}
              >
                {name}
              </motion.div>
              <ScrollTriggeredDiv
                animate={{
                  x: [-1000, 0],
                }}
                style={{ textAlign: 'center'}}
                transition={{ duration: 1.25 }}
              >
                {props.cvData.personalInformation.title}
              </ScrollTriggeredDiv>
            </p>
            <p style={{ textIndent: '20px' }}>
              {props.cvData.personalInformation.summary}
            </p>
            <p style={{ fontSize: '20px'}}>
              <ScrollTriggeredDiv type={MotionDivType.PTitle}>
                {props.cvData.areasOfExpertise.title}
              </ScrollTriggeredDiv>
            </p>
            {mapExpertise()}
            <p style={{ fontSize: '20px'}}>
              <ScrollTriggeredDiv type={MotionDivType.PTitle}>
                Technical Proficiencies
              </ScrollTriggeredDiv>
            </p>
            {mapTechnicalProficiencies()}
            <p style={{ fontSize: '20px'}}>
              <ScrollTriggeredDiv type={MotionDivType.PTitle}>
                Career Experience
              </ScrollTriggeredDiv>
            </p>
            {mapCareerExperience()}
            <p style={{ fontSize: '20px'}}>
              <ScrollTriggeredDiv type={MotionDivType.PTitle}>
                Education
              </ScrollTriggeredDiv>
            </p>
            {mapEducation()}
          </Col>
        </Row>
        <Row>
          <Col 
            xs={{span: 22, offset: 1}}
            md={{span: 20, offset: 2}}
            lg={{span: 18, offset: 3}}
            style={{height: '50px'}}
            className="cvBackground"
          ></Col>
        </Row>
      </Col>
    </Row>
    {showWelcomeEffects && (<CenterScreenModal>
        {getModalText()}
    </CenterScreenModal>)}
    </>
  )
}