import { useMemo, useState } from 'react';
import { Tooltip } from 'antd';
import { motion } from 'framer-motion';
import { tooltipColors, welcomePageIntroTimes } from '../../../constants';


const keyWordList = [
  {
    text: 'Azure',
    description: <span>All photos on this website are hosted in <b>Azure Blob Storage</b>.</span>
  },
  {
    text: 'AWS',
    description: <span><b>AWS Amplify</b> was used to host this website.</span>
  },
  {
    text: 'React (Typescript)',
    description: <span>This website was built using <b>React</b> with <b>Typescript</b>.</span>
  },
  {
    text: 'Full Stack Development',
    description: <span>This is my current job title. My day to day includes interactions with <b>React</b>, <b>GraphQL</b>, and <b>Azure</b></span>,
    appearsFirst: true
  }
]

interface HoverKeyWordProps {
  text: string;
  withComma?: boolean;
  showWelcomeEffects: boolean;
}

export const HoverKeyWord: React.FC<HoverKeyWordProps> = ({ text, withComma = false, showWelcomeEffects }) => {
  const [commaText] = useState(withComma ? ', ' : '');

  const keywordData = useMemo(
    () => keyWordList.find((keyWord) => keyWord.text.trim().toUpperCase() === text.trim().toUpperCase()),
    [text]
  );

  const randomColor = useMemo(
    () => tooltipColors[Math.floor(Math.random() * tooltipColors.length)],
    []
  );

  const boxShadowCountdownList = useMemo(() => {
    // count down from 100 to 0
    const list = [];
    for (let i = 100; i >= 0; i--) {
      list.push(`0 0 0 ${i}vmax rgba(0,0,0,.7)`);
    }
    return list;
  }, []);

  //check if the props.text is in the keyWordList
  if (keywordData) {
    const { description, appearsFirst } = keywordData;
    const tooltipContent = description;

    return (
      <Tooltip title={tooltipContent} color={randomColor}>
        <motion.span
          style={{
            color: randomColor,
            fontWeight: 625,
            display: 'inline-block',
            textShadow: '0.25px 0.25px black',
          }}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
        >
          {appearsFirst && showWelcomeEffects ? (
            <motion.span
              initial={{
                boxShadow: '0.0',
                borderRadius: '25px',
              }}
              animate={{
                boxShadow: ['0 0 0 0vmax rgba(0,0,0,.3)', ...boxShadowCountdownList],
                pointerEvents: ['none'],
                backgroundColor: ['white', 'transparent'],
                padding: ['0px 0px', '20px 20px', '20px 20px', '20px 20px', '0px 0px'],
              }}
              transition={{
                duration: welcomePageIntroTimes.durationInSec,
                delay: welcomePageIntroTimes.delayInSec,
              }}
            >
              <span style={{ cursor: 'context-menu' }}>{text}</span>
            </motion.span>
          ) : ( <span style={{ cursor: 'context-menu' }}>{text}</span> )}
        </motion.span>
        <span>{commaText}</span>
      </Tooltip>
    );
  }

  // Default text without hover effects
  return <span style={{ color: 'inherit' }}>{text + commaText}</span>;
};

export default HoverKeyWord;