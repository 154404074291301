// framer motion components types
export enum MotionDivType {
  P,
  PTitle
}

// other constantss
export const tooltipColors = [
  'pink',
  'orange',
  'green',
  'blue',
  'purple',
  'magenta',
  'volcano',
  'gold'
];

// used to control the duration and delay of the welcome page grey out when a user first visits the site
export const welcomePageIntroTimes = {
  durationInSec: 4,
  delayInSec: 2.25
}

// used to control how long the special effects on the welcome page should pause
export const pauseWelcomePageEffectsDurationInMinutes = 30;

export enum SESSION_STORAGE_KEYS {
  pauseWelcomeEffectsExpirationKey = 'pause-welcome-effects-expiration',
  chatContextId = 'chat-context-id'
}