import { pauseWelcomePageEffectsDurationInMinutes, SESSION_STORAGE_KEYS } from "../constants";

export const pauseWelcomeEffects = () => {
  const pauseWelcomeEffectsEpochTime = sessionStorage.getItem(SESSION_STORAGE_KEYS.pauseWelcomeEffectsExpirationKey);
  const currentEpochTime = Date.now();

  // Function to calculate the new expiration time
  const calculateNewExpiration = () => {
    const pauseEffectsInMilliseconds = pauseWelcomePageEffectsDurationInMinutes * 60 * 1000;
    return currentEpochTime + pauseEffectsInMilliseconds;
  }

  if (pauseWelcomeEffectsEpochTime === null) {
    console.log('No expiration found, enabling welcome effects');

    const newExpiration = calculateNewExpiration();
    sessionStorage.setItem(SESSION_STORAGE_KEYS.pauseWelcomeEffectsExpirationKey, newExpiration.toString());

    return true;
  } else {
    const parsedEpochTime = parseInt(pauseWelcomeEffectsEpochTime, 10);
    if (isNaN(parsedEpochTime)) {
      console.error('Invalid expiration value found, resetting expiration');

      const newExpiration = calculateNewExpiration();
      sessionStorage.setItem(SESSION_STORAGE_KEYS.pauseWelcomeEffectsExpirationKey, newExpiration.toString());

      return true;
    } else if (parsedEpochTime < currentEpochTime) {
      console.log('Expiration has passed, enabling welcome effects');
      
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.pauseWelcomeEffectsExpirationKey);

      return true;
    } else {
      console.log('Expiration is still active, disabling welcome effects');
      return false;
    }
  }
};