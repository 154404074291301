import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Grid } from 'antd';
import { welcomePageIntroTimes } from '../../../constants';

const { useBreakpoint } = Grid;

// shows a modal in the center of the screen
interface CenterScreenModalProps {
  children: any;
}

const motionProps = {
  key: "modalPopup",
  initial: { opacity: 0, scale: 0.5 },
  animate: { opacity: 1, scale: 1 },
  transition: {
    delay: welcomePageIntroTimes.delayInSec,
    ease: [0, 0.71, 0.2, 1.01],
    type: 'spring',
    stiffness: 500
  },
  exit: { opacity: 0, scale: 0.5 }
}

export const CenterScreenModal: React.FC<CenterScreenModalProps> = (props) => {
  const screens = useBreakpoint();
  const [isVisible, setIsVisible] = useState(true);
  
  useEffect(() => {
    const timeoutEnd = setTimeout(() => {
      setIsVisible(false);
    }, welcomePageIntroTimes.durationInSec * 1000);

    return () => {
      clearTimeout(timeoutEnd);
    };
  }, [])

  if (screens.md) {
    return ( 
      <>
      <AnimatePresence>
        {isVisible && <motion.div
          style={{
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0)'
          }}
          {...motionProps}
        >
          <div style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            border: '1px solid black',
            padding: '20px',
            width: '25%',
            textAlign: 'center'
          }}>
            {props.children}
          </div>
        </motion.div>}
      </AnimatePresence>
      </>
    )
  } else if (screens.sm) {
    return ( 
      <>
      <AnimatePresence>
        {isVisible && <motion.div
          style={{
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0)'
          }}
          {...motionProps}
        >
          <div style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            border: '1px solid black',
            padding: '20px',
            width: '50%',
            textAlign: 'center'
          }}>
            {props.children}
          </div>
        </motion.div>}
      </AnimatePresence>
      </>
    )
  } else {
    return ( 
      <>
      <AnimatePresence>
        {isVisible && <motion.div
          style={{
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0)'
          }}
          {...motionProps}
        >
          <div style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            border: '1px solid black',
            padding: '20px',
            width: '75%',
            textAlign: 'center'
          }}>
            {props.children}
          </div>
        </motion.div>}
      </AnimatePresence>
      </>
    )
  }
}

export default CenterScreenModal;